import React from 'react'
import styled from 'styled-components'
import { Image, Card } from '@potluckprotocol/uikit'
import { CommunityTag } from 'components/Tags'

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => (theme.isDark ? '#524B63' : '#E9EAEB')};
  padding: 24px;
`
const Coming: React.FC = () => {
  return (
    <Card style={{ width: '400px' }}>
      <div style={{ padding: '24px' }}>
        <Image src="/images/OBSERVATORYEMAIL.jpg" width={350} height={350} alt="Your project here" />
      </div>
      <Footer>
        <CommunityTag />
      </Footer>
    </Card>
  )
}

export default Coming
