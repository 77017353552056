/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PriceApiResponse, PriceState, PriceApiThunk } from 'state/types'


const initialState: PriceState = {
  isLoading: false,
  lastUpdated: null,
  data: null,
}

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiThunk>('prices/fetch', async () => {
  try
  {
  const response1 = await fetch(`https://api.potluckprotocol.com/api/ftm/tokens`)
  const data1 = await response1.text()
  const dataJson = JSON.parse(data1.toLowerCase());

 

  const data = {
    updated_at: "0",
    data:{}
  }  as PriceApiResponse

 
  // Return normalized token names
  const prices = {
    updated_at: data.updated_at,
    data: dataJson,
  }
  return prices
}
catch{
  return {
    updated_at: "0",
    data:{}
  } as PriceApiResponse
}
})

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPrices.rejected,(state)=> {
      state.isLoading = true
    })
    builder.addCase(fetchPrices.fulfilled, (state, action: PayloadAction<PriceApiThunk>) => {
      state.isLoading = false
      state.lastUpdated = action.payload.updated_at
      state.data = action.payload.data
    })
  },
})

export default pricesSlice.reducer
