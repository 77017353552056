import React from 'react'
import { Flex, Skeleton, Text } from '@potluckprotocol/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js'
import { Pool } from 'state/types'
import BaseCell, { CellContent } from './BaseCell'

interface HarvestIntervalCellProps {
  pool: Pool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const calculateHarvestLockup = (fee: BigNumber) => {
  if (fee.eq(0)) {
    return '0 Hour(s)'
  }

  return `${Number(fee).toLocaleString(undefined, { maximumFractionDigits: 0 })} Hour(s)`
}

const HarvestIntervalCell: React.FC<HarvestIntervalCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { harvestInterval } = pool
  const displayHarvestLockup = harvestInterval ? (
    calculateHarvestLockup(new BigNumber(harvestInterval))
  ) : (
    <Skeleton width={60} />
  )
  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {t('Withdrawal Lockup')}
        </Text>
        <Flex alignItems="center">
          <Text bold>{displayHarvestLockup}</Text>
        </Flex>
      </CellContent>
    </StyledCell>
  )
}

export default HarvestIntervalCell
