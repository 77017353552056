import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'


// Addresses
import {
  getAddress,
  getCakeAddress,
  getMasterChefAddress,
  getMulticallAddress,
  getBatBattleAddress,
  getZapperAddress,
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import batBattle from 'config/abi/batBattle.json'
import zapperAbi from 'config/abi/zapper.json'

const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}



export const getBep20Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getSouschefContract = (
  id: number,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi =
    config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer)
}
export const getSouschefV2Contract = (
  id: number,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer)
}

export const getBatBattleContract = (
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(batBattle, getBatBattleAddress(), signer)
}

export const getZapperContract = (signer?: ethers.Signer | ethers.providers.Provider,) => {
  return getContract(zapperAbi, getZapperAddress(), signer)
}

export const getCakeContract = (
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(cakeAbi, getCakeAddress(), signer)
}

export const getMasterchefContract = (
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}

export const getMulticallContract = (
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
