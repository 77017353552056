import { createSlice } from '@reduxjs/toolkit'
import { BattlesState, BattlesData, BattleHistory } from 'state/types'

import { fetchBattleHistory } from './helpers'
import { fetchBattleCount, fetchBattles, fetchUserBattle } from './fetchBattle'

const initialState = {
  battles: [],
  activeBattles: [],
  activeCount: 0,
  userDataLoaded: false,
  userBattle: false,
} as BattlesState

// // Thunks
export const fetchBattlesPublicDataAsync = () => async (dispatch) => {
  const battleHistory = await fetchBattleHistory()
  const count = await fetchBattleCount()
  const battlesData = await fetchBattles(Number(count))
  dispatch(setBattlesHistoryPublicData(battleHistory))
  dispatch(setBattlesCount(count))
  dispatch(setBattlesData(battlesData))
}

export const fetchUserBattleExists = (account: string) => async (dispatch) => {
  if (account) {
    const userBattle = await fetchUserBattle(account)
    dispatch(setUserBattleData(userBattle))
  } else {
    dispatch(setUserBattleData(false))
  }
}

export const fetchBattlesCount = () => async (dispatch) => {
  const count = await fetchBattleCount()
  dispatch(setBattlesCount(count))
}

export const BattlesSlice = createSlice({
  name: 'Battles',
  initialState,
  reducers: {
    setBattlesHistoryPublicData: (state, action) => {
      const battlesHistory: BattleHistory[] = action.payload
      state.battles = battlesHistory
    },
    setBattlesCount: (state, action) => {
      const battleCount: number = action.payload
      state.activeCount = Number(battleCount)
    },
    setBattlesData: (state, action) => {
      const battlesData: BattlesData[] = action.payload
      state.activeBattles = battlesData
      state.userDataLoaded = true
    },
    setUserBattleData: (state, action) => {
      const userBattle: boolean = action.payload
      state.userBattle = userBattle
    },
  },
})

// Actions
export const {
  setBattlesHistoryPublicData,
  setBattlesCount,
  setBattlesData,
  setUserBattleData,
} = BattlesSlice.actions

export default BattlesSlice.reducer
