import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { useAppDispatch } from 'state'
import { fetchPrices } from '.'
import { State, PriceState } from '../types'


export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPrices())
  }, [dispatch, slowRefresh])

}

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data)
  return prices
}
