import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR, CAKE_PER_YEAR } from 'config'


/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getFarmApr = (
  poolWeight: number,
  cakePriceUsd: number,
  poolLiquidityUsd: number,
  farmAddress: string,
): { cakeRewardsApr: number; lpRewardsApr: number } => {

  const yearlyCakeRewardAllocation = CAKE_PER_YEAR.toNumber() * poolWeight
  const cakeRewardsApr = yearlyCakeRewardAllocation * cakePriceUsd / poolLiquidityUsd * 100
  let cakeRewardsAprAsNumber = null
  if(farmAddress === ""){
    return null
  }
  if (!Number.isNaN(cakeRewardsApr) && Number.isFinite(cakeRewardsApr)) {
    cakeRewardsAprAsNumber = cakeRewardsApr
  }
  const lpRewardsApr = 0
  
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
}

export default null
