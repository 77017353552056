import tokens from './tokens'
import pairs from './pairs'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  
  {
    sousId: 9,
    isObservatory:true,
    stakingToken: tokens.gmftm,
    earningToken: tokens.gmftm,
    contractAddress: {
      97: '',
      250: '0x311e1316fdD02eb7CA5BA1D5D747958997f9C21C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01103394891',
    sortOrder: 1,
    isFinished: false,
  },
  
  {
    sousId: 10,
    isObservatory:true,
    stakingToken: tokens.gmftm,
    earningToken: tokens.gmftm,
    contractAddress: {
      97: '',
      250: '0x533e5Ccd088f3A2Da67979263cA9e7Fd6aa648d4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01273147951',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 11,
    isObservatory:true,
    stakingToken: tokens.gmftm,
    earningToken: tokens.gmftm,
    contractAddress: {
      97: '',
      250: '0x50Da36Cd62E1949882a262107513eFa21b22C714',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01100585599',
    sortOrder: 1,
    isFinished: false,
  },
  
]

export default pools
