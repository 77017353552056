import { DEFAULT_GAS_LIMIT } from 'config'
import getGasPrice from 'utils/getGasPrice'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const cancelBattle = async (batbattleContract) => {
  const gasPrice = getGasPrice()
  const tx = await batbattleContract.cancelBattle({ ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}
