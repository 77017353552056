import {useGetApiPrices} from "state/prices/hooks"

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */

const  useGetPriceData = () => {
  const prices = useGetApiPrices()
  const cakePriceUsd = prices ? prices['0x49894fcc07233957c35462cfc3418ef0cc26129f'] ? Number(prices['0x49894fcc07233957c35462cfc3418ef0cc26129f']) : 0 : 0;
  return cakePriceUsd
}



export default useGetPriceData
