import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "state";
import { getAddress } from "utils/addressHelpers";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { BIG_ZERO } from "utils/bigNumber";
import { getBalanceAmount } from "utils/formatBalance";
import { farmsConfig } from "config/constants";
import useRefresh from "hooks/useRefresh";
import { useGetApiPrices } from "state/prices/hooks";
import {
  fetchFarmsPublicDataAsync,
  fetchFarmUserDataAsync,
  nonArchivedFarms,
} from ".";
import { State, Farm, FarmsState } from "../types";

export const usePollFarmsPublicData = (includeArchive = false) => {
  const dispatch = useAppDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms;
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid);

    dispatch(fetchFarmsPublicDataAsync(pids));
  }, [includeArchive, dispatch, slowRefresh]);
};

export const usePollFarmsWithUserData = (includeArchive = false) => {
  const dispatch = useAppDispatch();
  const { slowRefresh } = useRefresh();
  const { account } = useWeb3React();

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms;
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid);

    dispatch(fetchFarmsPublicDataAsync(pids));

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }));
    }
  }, [includeArchive, dispatch, slowRefresh, account]);
};

/**
 * Fetches the "core" farm data used globally
 * 251 = CAKE-FTM LP
 * 252 = BUSD-FTM LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch();
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync([]));
  }, [dispatch, fastRefresh]);
};

export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms);
  return farms;
};

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) =>
    state.farms.data.find((f) => f.pid === pid)
  );
  return farm;
};

export const useFarmFromLpSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) =>
    state.farms.data.find((f) => f.lpSymbol === lpSymbol)
  );
  return farm;
};

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid);

  return {
    allowance: farm.userData
      ? new BigNumber(farm.userData.allowance)
      : BIG_ZERO,
    tokenBalance: farm.userData
      ? new BigNumber(farm.userData.tokenBalance)
      : BIG_ZERO,
    stakedBalance: farm.userData
      ? new BigNumber(farm.userData.stakedBalance)
      : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
  };
};

export const useTotalFarmsValue = (farmsLP): BigNumber => {
  const prices = useGetApiPrices();
  const bnbPrice = prices
    ? new BigNumber(prices["0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"])
    : new BigNumber(0);
  let value = new BigNumber(0);
  // farms
  for (let i = 0; i < farmsLP.length; i++) {
    const farm = farmsLP[i];
    if (farm.lpTotalInQuoteToken) {
      let val = 0;
      if (
        farm.quoteToken.symbol === "WFTM" ||
        farm.quoteToken.symbol === "FTM"
      ) {
        val = bnbPrice.toNumber() * farm.lpTotalInQuoteToken;
      } else if (farm.quoteToken.symbol === "FANG") {
        val = prices
          ? farm.lpTotalInQuoteToken *
            prices[getAddress(farm.quoteToken.address)]
          : 0;
      } else if (prices != null) {
        const quoteTokenPriceUsd = prices[getAddress(farm.quoteToken.address)];
        const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(
          quoteTokenPriceUsd
        );
        val = totalLiquidity.toNumber();
      } else {
        val = 0;
      }
      if (!Number.isNaN(val)) value = value.plus(val);
    }
  }
  return value;
};

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid);
  return farm && new BigNumber(farm.token.busdPrice);
};

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol);
  const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid);
  let lpTokenPrice = BIG_ZERO;

  if (farm.lpTotalSupply && farm.lpTotalInQuoteToken) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(
      farm.tokenAmountTotal
    );
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2);
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(new BigNumber(farm.lpTotalSupply));
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens);
  }
  return lpTokenPrice;
};

// /!\ Deprecated , use the BUSD hook in /hooks

export const usePriceBnbBusd = (): BigNumber => {
  const bnbBusdFarm = useFarmFromPid(3);
  return new BigNumber(bnbBusdFarm.quoteToken.busdPrice);
};

export const usePriceCakeBusd = (): BigNumber => {
  const cakeBnbFarm = useFarmFromPid(26);

  const cakePriceBusdAsString = cakeBnbFarm.token.busdPrice;

  const cakePriceBusd = useMemo(() => {
    return new BigNumber(cakePriceBusdAsString);
  }, [cakePriceBusdAsString]);

  return cakePriceBusd;
};
