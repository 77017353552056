import { useEffect } from 'react'
import {useGetApiPrices} from "state/prices/hooks"


const useGetDocumentTitlePrice = () => {
  const prices = useGetApiPrices()
  const cakePriceUsd = prices ? prices['0x49894fcc07233957c35462cfc3418ef0cc26129f'] ? Number(prices['0x49894fcc07233957c35462cfc3418ef0cc26129f']) : 0 : 0;
  const cakePriceUsdBN = cakePriceUsd ? cakePriceUsd.toFixed(6) : ''
  useEffect(() => {
    document.title = `FANG - ${cakePriceUsdBN}$`
  }, [cakePriceUsdBN])
}
export default useGetDocumentTitlePrice
