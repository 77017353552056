import React from 'react'
import { Flex, Skeleton, Text } from '@potluckprotocol/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js'
import { Pool } from 'state/types'
import BaseCell, { CellContent } from './BaseCell'

interface DepositFeeCellProps {
  pool: Pool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const calculateDepositFee = (fee: BigNumber) => {
  if (fee.eq(0)) {
    return '0%'
  }

  return `${Number(fee).toLocaleString(undefined, { maximumFractionDigits: 0 })}%`
}

const DepositFeeCell: React.FC<DepositFeeCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { depositFee } = pool
  const displayDepositFee = depositFee ? calculateDepositFee(new BigNumber(depositFee)) : <Skeleton width={60} />
  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {t('Deposit Fee')}
        </Text>
        <Flex alignItems="center">
          <Text bold>{displayDepositFee}</Text>
        </Flex>
      </CellContent>
    </StyledCell>
  )
}

export default DepositFeeCell
