import { ChainId } from '@potluckprotocol/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getZapperAddress = () => {
  return getAddress(addresses.zapper)
}

export const getSpiritRouterAddress = () => {
  return getAddress(addresses.spiritRouter)
}

export const getSpiritAddress = () => {
  return getAddress(addresses.spirit)
}


export const getSpookyRouterAddress = () => {
  return getAddress(addresses.spooky)
}



export const getBatBattleAddress = () => {
  return getAddress(addresses.batbattle)
}

export const getCakeAddress = () => {
  return getAddress(tokens.fang.address)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.wftm.address)
}

export const getReferralsAddress = () => {
  return getAddress(addresses.referrals)
}
