import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { getAddress } from 'utils/addressHelpers'
import { simpleRpcProvider } from 'utils/providers'
import useRefresh from 'hooks/useRefresh'
import {useGetApiPrices} from "state/prices/hooks"
import BigNumber from 'bignumber.js'
import { fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync, fetchPoolsStakingLimitsAsync } from '.'
import { State, Pool } from '../types'
import { transformPool } from './helpers'

export const useFetchPublicPoolsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const prices = useGetApiPrices()
  
  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await simpleRpcProvider.getBlockNumber()
      if(prices != null)
      dispatch(fetchPoolsPublicDataAsync(blockNumber,prices))
      else
      dispatch(fetchPoolsPublicDataAsync(blockNumber,{}))
    }
    fetchPoolsPublicData()
    dispatch(fetchPoolsStakingLimitsAsync())
  }, [dispatch, slowRefresh, prices])
}

export const useTotalPoolsValue = (pools): BigNumber => {
  const prices = useGetApiPrices()
  

  let value = new BigNumber(0)

  // bush (pools)
  for (let i = 0; i < pools.length; i++) {
    const bush = pools[i]
    // total liquidity
    let bushValue = new BigNumber(0)
    if (bush.stakingToken.symbol === 'FANG') {
      bushValue = new BigNumber(bush.totalStaked).div(new BigNumber(10).pow(18)).multipliedBy(bush.stakingTokenPrice)
    }

     if (prices != null) {
      const poolTokenPrice =  prices[getAddress(bush.stakingToken.address)]
      bushValue = new BigNumber(bush.totalStaked)
        .div(new BigNumber(10).pow(bush.stakingToken.decimals))
        .multipliedBy(poolTokenPrice)
     }

    if (!bushValue.isNaN()) {
      value = value.plus(bushValue)
    }
  }

  return value
}

export const usePools = (account): { pools: Pool[]; userDataLoaded: boolean } => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const { pools, userDataLoaded } = useSelector((state: State) => ({
    pools: state.pools.data,
    userDataLoaded: state.pools.userDataLoaded,
  }))
  return { pools: pools.map(transformPool), userDataLoaded }
}
