export default {
  masterChef: {
    97: '',
    56: '',
    250: '0x106804d24E0B7AB997D4b7Ab5cD5d8923C22707F',
  },
  referrals: {
    97: '',
    56: '',
    250: '0x6034d727a987E8002e9e54C1ED523109708da18A',
  },
  multiCall: {
    56: '',
    97: '',
    250: '0xBAD2B082e2212DE4B065F636CA4e5e0717623d18',
  },
  batbattle: {
    56: '',
    250: '0x6111ec399Fe1Af4B8ad97dF8525467400874681f',
    97: '',
  },
  zapper: {
    56:'',
    250:'0xF0ff07d19f310abab54724a8876Eee71E338c82F',
    97:''
  },
  spiritRouter: {
    56:'',
    250:'0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52',
    97:''
  },
  spirit: {
    56:'',
    250:'0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
    97:''
  },
  spooky: {
    56:'',
    250:'0xF491e7B69E4244ad4002BC14e878a34207E38c29',
    97:''
  }
}
