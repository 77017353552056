import { getBatBattleContract } from 'utils/contractHelpers'
import { BattlesData } from 'state/types'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'

const batBattleContract = getBatBattleContract()

export const fetchBattleCount = async () => {
  const count = await batBattleContract.countBattles()
  return new BigNumber(count.toString()).toJSON()
}

export const fetchUserBattle = async (address) => {
  const contractData = await batBattleContract.Battles(address)
  if (contractData[0] !== '0x0000000000000000000000000000000000000000') {
    return true
  }
  return false
}

export const fetchBattles = async (count: number) => {
  const battlesData: BattlesData[] = []
  /* eslint-disable no-await-in-loop */
  for (let i = 0; i < count; i++) {
    const battleAddress = await batBattleContract.BattleList(i)
    const contractData = await batBattleContract.Battles(battleAddress)
    const creator = contractData ? contractData.creator : null
    const time = contractData ? new BigNumber(contractData.time._hex) : BIG_ZERO
    const value = contractData
      ? new BigNumber(contractData.value._hex).div(10 ** 18)
      : BIG_ZERO
    const seed = contractData ? contractData.seed : null
    const betFor = contractData
      ? new BigNumber(contractData.betFor._hex)
      : BIG_ZERO

    const data = {
      creator,
      time: time.toJSON(),
      value: value.toJSON(),
      seed,
      betFor: betFor.toJSON(),
    } as BattlesData

    battlesData.push(data)
  }

  return battlesData
}
