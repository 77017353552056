import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ethers } from 'ethers'
import { State } from 'state/types'
import fetchReferralInfo from 'state/referrals/fetchReferralsInfo'
import Cookies from 'js-cookie'

const saveReferrer = async (account, ref) => {
  if (account === ref) {
    localStorage.removeItem('REFERRER')
    Cookies.remove('referral_code')
    return
  }
  const referralData = await fetchReferralInfo(account)
  if (referralData.referrer === '0x0000000000000000000000000000000000000000') {
    localStorage.setItem('REFERRER', ref)
    Cookies.set('referral_code', ref, { expires: 365 })
  }
}

export const useSaveReferrer = () => {
  // eslint-disable-next-line
  const search = window.location.search
  const ref = new URLSearchParams(search).get('ref')
  const { account } = useActiveWeb3React()
  useEffect(() => {
    if (account === localStorage.getItem('REFERRER') || account === Cookies.get('referral_code')) {
      localStorage.removeItem('REFERRER')
      Cookies.remove('referral_code')
    }

    if (ethers.utils.isAddress(ref)) {
      localStorage.setItem('REFERRER', ref)
      Cookies.set('referral_code', ref, { expires: 365 })
    }
    if (account && ref) {
      saveReferrer(account, ref)
    }
  }, [account, ref])
}

export const useGetReferralInfo = () => {
  const refData = useSelector((state: State) => state.referrals.data)
  return refData
}
