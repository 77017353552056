import { MenuEntry } from '@potluckprotocol/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade(Spooky)'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/pool',
      }
    ],
  },
  {
    label: t('Trade(Spirit)'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: 'https://www.spiritswap.finance/swap',
      },
      {
        label: t('Liquidity'),
        href: 'https://www.spiritswap.finance/liquidity',
      },
    ],
  },
  {
    label: 'Rubic Cross Chain Swap',
    icon: 'RubicIcon',
    href: '/rubic',
  },
  {
    label: 'FANG GANG SWAG',
    icon: 'SonarIcon',
    href: 'https://potluck-protocol.myshopify.com/',
  },
  {
    label: 'The Cave',
    icon: 'CaveIcon',
    href: '/cave',
  },
  {
    label: 'The Attic',
    icon: 'AtticIcon',
    href: '/attic',
  },
  // {
  //   label: 'The Bat Box',
  //   icon: 'BatboxIcon',
  //   href: '/batbox',
  // },
  {
    label: 'The Observatory',
    icon: 'ObservatoryIcon',
    href: '/observatory',
  },
  // {
  //   label: 'The Bat Battle',
  //   icon: 'BatbattleIcon',
  //   href: '/batbattle',
  // },
  {
    label: 'Bridge',
    icon: 'TradeIcon',
    href: 'https://spooky.fi/#/bridge',
  },
  {
    label: 'Audits',
    icon: 'AuditIcon',
    items: [
      {
        label: 'RugDoc Review',
        href: 'https://rugdoc.io/project/potluck-protocol-fantom/',
      },
      {
        label: 'DessertFinance Audit',
        href: 'https://dessertswap.finance/audits/Potluck%20BSC-FTM%20audit%2011437133.pdf',
      }
    ],
  },
  {
    label: 'Charts',
    icon: 'InfoIcon',
    items: [
      {
        label:'Nomics',
        href: 'https://nomics.com/assets/fang2-fang-token'
      },
      {
        label: 'Zoocoin Charts',
        href: 'https://charts.zoocoin.cash/charts?exchange=SpookySwap&pair=0x49894fCC07233957c35462cfC3418Ef0CC26129f-inverted',
      },
      {
        label: 'Kek Charts',
        href: 'https://kek.tools/t/0x49894fCC07233957c35462cfC3418Ef0CC26129f',
      }
    ],
  },
  {
    label: 'Info',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Roadmap',
        href: 'https://potluckprotocol.gitbook.io/docs/',
      },
      {
        label: 'Tokenomics',
        href: 'https://potluckprotocol.gitbook.io/docs/fang-tokenomics',
      },
    ],
  },
  // {
  //   label: 'Sonar (coming soon)',
  //   icon: 'HamburgerCloseIcon',
  //   href: 'https://app.potluckprotocol.com/#/',
  // },
]

export default config
