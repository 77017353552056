import React from 'react'
import BigNumber from 'bignumber.js'
import { Menu as UikitMenu } from '@potluckprotocol/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import {useGetApiPrices} from "state/prices/hooks"
import config from './config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()

  const prices = useGetApiPrices()
  
  const cakePriceUsd = prices ? prices['0x49894fcc07233957c35462cfc3418ef0cc26129f'] ? Number(prices['0x49894fcc07233957c35462cfc3418ef0cc26129f']) : 0 : 0;
  const ptlkxPriceUsd = prices ? prices['0x546d10c1378febef063b72ae4865496ad801c386'] ? Number(prices['0x546d10c1378febef063b72ae4865496ad801c386']) : 0 : 0;
  
  const ptlkxPriceBN = new BigNumber(ptlkxPriceUsd ?  ptlkxPriceUsd.toFixed(4) : '')
  const cakePriceUsdBN = new BigNumber(cakePriceUsd ? cakePriceUsd.toFixed(4) : '')
  const { currentLanguage, setLanguage, t } = useTranslation()

  return (
    <UikitMenu
      userMenu={<UserMenu />}
      globalMenu={<GlobalSettings />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={cakePriceUsdBN.toNumber()}
      ptlkxPriceUsd={ptlkxPriceBN.toNumber()}
      links={config(t)}
      profile={null}
      {...props}
    />
  )
}

export default Menu
