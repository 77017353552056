import React from 'react'
import { useTranslation } from 'contexts/Localization'
import { Text } from '@potluckprotocol/uikit'

const PtlkxWarning = () => {
  const { t } = useTranslation()

  return (
    <>
      <Text>{t('To trade PTLKX, you must:')} </Text>
      <Text>• {t('Click on the settings icon')}</Text>
      <Text mb="24px">• {t('Set your slippage tolerance to 10%+')}</Text>
      <Text>{t('This is because PTLKX taxes a 1% fee on each transaction:')}</Text>
      <Text>• {t('0.5% fee = burned')}</Text>
      <Text>• {t('0.5% fee = used to add liquidity')}</Text>
    </>
  )
}

export default PtlkxWarning
