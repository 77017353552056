import React from 'react'
import { Flex, Skeleton, Text } from '@potluckprotocol/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js'
import { Pool } from 'state/types'
import BaseCell, { CellContent } from './BaseCell'

interface TransferFeeCellProps {
  pool: Pool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const calculateTransferFee = (fee: BigNumber) => {
  if (fee.eq(0)) {
    return '0%'
  }

  return `${Number(fee).toLocaleString(undefined, { maximumFractionDigits: 0 })}%`
}

const TransferFeeCell: React.FC<TransferFeeCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { transferFee } = pool
  const displayHarvestLockup = transferFee ? calculateTransferFee(new BigNumber(transferFee)) : <Skeleton width={60} />
  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {t('Transfer Fee')}
        </Text>
        <Flex alignItems="center">
          <Text bold>{displayHarvestLockup}</Text>
        </Flex>
      </CellContent>
    </StyledCell>
  )
}

export default TransferFeeCell
